.fullscreen {
  width: 100%;
  height: 100vh;
  background-color: #000B29;
}

.heading {
  position: fixed;
  color: white;
  z-index: 1000;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  font-size: 10rem;
  pointer-events: none;
  user-select: none;
  font-family: sans-serif;
  margin: 0;
  padding: 0;
}

.heading div {
  display: inline;
}

.red {
  color: red;
}